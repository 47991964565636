
import Vue from "vue";
import FormInput from "../forms/input.vue";
import FormRadio from "../forms/radio.vue";

export default Vue.extend({
  name: "AuthLoginForm",

  data: () => {
    return {
      email: "",
      errorMsg: "",
      isAdmin: false,
      isJury: false,
      isLoading: false,
      isPress: false,
      loginField: {
        name: "login",
        label: "Login",
        type: "radio",
      },
      loginType: "old",
      pass: "",
      pass_confirm: "",
      privacy: false,
      privacyDescription:
        'Ich erkläre mich mit den <a target="_blank" href="https://www.bim.bayern.de/datenschutz/" rel="noopener">Datenschutzbestimmungen</a> einverstanden.',
    };
  },

  components: {
    FormInput,
    FormRadio,
  },

  computed: {
    authUser() {
      return this.$store.state.user;
    },
    baseUrl() {
      return this.$store.state.baseUrl;
    },
    darkMode() {
      return this.$store.state.darkMode;
    },
    exportMode() {
      return this.$store.state.exportMode;
    },
    juryMode() {
      return this.$store.state.juryMode;
    },
    pressMode() {
      return this.$store.state.pressMode;
    },
  },

  methods: {
    async submitLoginForm() {
      if (this.loginType === "old") {
        this.sendLogin();
      } else if (this.loginType === "pass") {
        this.requestNewPassword();
      }
    },

    async sendLogin() {
      this.errorMsg = "";
      this.isLoading = true;
      // validation
      if (!this.email || !this.pass) {
        return (this.errorMsg =
          "Die E-Mail und Passwort Felder müssen ausgefüllt sein.");
      }

      try {
        // login request
        const data = {
          username: this.email,
          password: this.pass,
        };
        const response = await fetch(this.baseUrl + "login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        const res = await response.json();
        this.isLoading = false;
        if (response.status >= 400) {
          throw new Error(response.statusText);
        } else {
          if (!res.error && res.user) {
            const { roles } = res.user;
            this.isAdmin = false;
            this.$store.commit("setUser", res.user);
            if (
              roles &&
              roles.includes("ROLE_ADMIN") &&
              !this.pressMode &&
              !this.juryMode
            ) {
              this.isAdmin = true;
              this.$router.push("/admin");
            } else if (
              this.exportMode &&
              roles &&
              (roles.includes("ROLE_EXPORT") || roles.includes("ROLE_ADMIN"))
            ) {
              this.$router.push("/export/index");
            } else if (
              this.pressMode &&
              roles &&
              (roles.includes("ROLE_PRESS") || roles.includes("ROLE_ADMIN"))
            ) {
              this.$router.push("/presse/index");
            } else if (
              this.juryMode &&
              roles &&
              (roles.includes("ROLE_JURY") || roles.includes("ROLE_ADMIN"))
            ) {
              this.$router.push("/jury/index");
            } else {
              this.$router.push("/anmelden/index");
            }
            this.$store.dispatch("getAppInfo");
          } else {
            throw new Error(res.error);
          }
        }
      } catch (error) {
        console.error("sendLogin error -> ", (error as any).message);
        this.isLoading = false;
        this.$store.commit("setAlert", {
          type: "error",
          message: "Fehler bei der Anmeldung, bitte überprüfen sie Ihre Daten.",
        });
        this.errorMsg =
          "Die E-Mail und Passwort Felder müssen ausgefüllt sein.";
      }
    },

    async requestNewPassword() {
      this.errorMsg = "";
      if (!this.email) {
        return (this.errorMsg = "E-Mail muss ausgefüllt sein.");
      }

      try {
        const data = {
          email: this.email,
        };
        const response = await fetch(this.baseUrl + "user/lostpassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        const res = await response.json();
        console.log("requestNewPassword: ", res);
        // for this feature we always give success message
        this.$store.commit("setAlert", {
          type: "info",
          message: "Weitere Instruktionen wurden Ihnen zugesendet.",
        });
        this.loginType = "old";
      } catch (error) {
        console.error("requestNewPassword error -> ", (error as any).message);
      }
    },
  },

  watch: {
    loginType() {
      this.errorMsg = "";
    },
  },

  mounted() {
    if (this.authUser) {
      if (this.authUser.roles && this.authUser.roles.includes("ROLE_ADMIN")) {
        this.isAdmin = true;
        if (this.authUser.roles.includes("ROLE_EXPORT")) {
          this.$router.push("/export/index");
        } else if (this.authUser.roles.includes("ROLE_PRESS")) {
          this.$router.push("/presse/index");
        } else if (this.authUser.roles.includes("ROLE_JURY")) {
          this.$router.push("/jury/index");
        } else {
          this.$router.push("/admin");
        }
      } else if (
        this.authUser.roles &&
        this.authUser.roles.includes("ROLE_EXPORT")
      ) {
        this.$router.push("/export/index");
      } else if (
        this.authUser.roles &&
        this.authUser.roles.includes("ROLE_PRESS")
      ) {
        this.$router.push("/presse/index");
      } else if (
        this.authUser.roles &&
        this.authUser.roles.includes("ROLE_JURY")
      ) {
        this.$router.push("/jury/index");
      }
    }
  },
});
